import React, { useState, useEffect, useRef } from "react";
import Sidebar from "../../components/Sidebar";
import axios from "axios";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import ReactPaginate from "react-paginate";
import Loader from "react-loader-spinner";

const PER_PAGE = 10;

const PrivatePage = () => {
  const [filteredPages, setFilteredPages] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const privatePagesRef = useRef([]);
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState(""); 

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_PROD_HOST}/private/privatepages`)
      .then((res) => {
        const privatePages = res.data;
        privatePagesRef.current = privatePages;
        setFilteredPages(privatePages);
        setLoading(true);
      });
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearchQuery(searchQuery); 
    }, 500);

    return () => {
      clearTimeout(timer); 
    };
  }, [searchQuery]);

  useEffect(() => {
    
    const filtered = privatePagesRef.current.filter((page) =>
      page.title.toLowerCase().includes(debouncedSearchQuery.toLowerCase())
    );
    setFilteredPages(filtered);
    setCurrentPage(0); // Reset to first page
  }, [debouncedSearchQuery]);

  const deleteItem = (_id) => {
    swal({
      title: "Are you sure?",
      text: "Do your really want to remove?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        axios
          .delete(
            `${process.env.REACT_APP_PROD_HOST}/private/delete_privatepage/${_id}`
          )
          .then(() => {
            privatePagesRef.current = privatePagesRef.current.filter(
              (page) => page._id !== _id
            );
            setFilteredPages(privatePagesRef.current);
          });
      }
    });
  };

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  const clearSearch = () => {
    setSearchQuery("");
    setDebouncedSearchQuery("");
    setFilteredPages(privatePagesRef.current); 
    setCurrentPage(0);
  };

  const offset = currentPage * PER_PAGE;
  const currentPageData =
    filteredPages &&
    filteredPages.slice(offset, offset + PER_PAGE).map((PrivatePage, index) => {
      return (
        <tr key={index}>
          <td>{offset + index + 1}</td>
          <td>{PrivatePage.title}</td>
          <td>
            <Link to={`/view_privatepage/${PrivatePage.urlName}`}>
              <span className="btn">View</span>
            </Link>

            <Link to={`/edit_privatepage/${PrivatePage.urlName}`}>
              <span className="btn">Edit</span>
            </Link>
            <span
              className="btn"
              onClick={() => deleteItem(PrivatePage._id)}
            >
              Delete
            </span>
          </td>
        </tr>
      );
    });

  const pageCount = Math.ceil(filteredPages.length / PER_PAGE);

  return (
    <div>
      <Sidebar></Sidebar>
      <div className="admin-wrapper col-12">
        <div className="admin-content">
          <div className="admin-head">Private Page</div>
          {loading ? (
            <div className="admin-data">
          
              <div className="col-lg-12 p-0 mb-30 d-flex justify-content-between align-items-center">
                <div className="search-bar position-relative w-50">
                  <input
                    type="text"
                    placeholder="Search by title..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)} 
                    className="form-control"
                  />
                  {searchQuery && (
                    <span
                      onClick={clearSearch}
                      style={{
                        position: "absolute",
                        right: "10px",
                        top: "50%",
                        transform: "translateY(-50%)",
                        cursor: "pointer",
                        fontSize: "18px",
                        color: "#666",
                      }}
                    >
                      &#x2715;
                    </span>
                  )}
                </div>
                <a href="add_privatepage">
                  <button className="button button-contactForm boxed-btn">
                    + Add New
                  </button>
                </a>
              </div>
              {/* Table */}
              <div className="table-responsive admin-table">
                <table>
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Title</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>{currentPageData}</tbody>
                </table>
              </div>

              {/* Pagination */}
              <div className="paginationstyle">
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  pageCount={pageCount}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination"}
                  previousLinkClassName={"pagination__link"}
                  nextLinkClassName={"pagination__link"}
                  disabledClassName={"pagination__link--disabled"}
                  activeClassName={"pagination__link--active"}
                />
              </div>
            </div>
          ) : (
            <div style={{ marginLeft: "500px", marginTop: "200px" }}>
              <Loader
                type="Circles"
                color="#0029ff"
                height={100}
                width={100}
                timeout={3000} // 3 seconds
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PrivatePage;
